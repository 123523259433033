import 'unpoly/unpoly.min'
import Splide from '@splidejs/splide'
import Plyr from 'plyr'
import lightGallery from 'lightgallery'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import Cleave from 'cleave.js'
require('cleave.js/dist/addons/cleave-phone.ch');
import hyperform from 'hyperform'

up.compiler('body', () => {
    document.body.classList.remove('no-js')
})

up.compiler('.js-navbar .js-anchor', (element, target) => {
  const navItem = element
  const section = document.querySelector(`${target.replace(document.documentElement.getAttribute('lang').split('-')[0], '').replace('//', '')}`)

  function observerCallback(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        navItem.classList.add('active')
      } else {
        navItem.classList.remove('active')
      }
    })
  }

  let observer = new IntersectionObserver(observerCallback, {threshold: 0.51})

  if (section) {
    observer.observe(section)
  }
})

up.compiler('.js-nav-toggle', (navToggle) => {
  const offCanvas = document.querySelector('.off-canvas')

  navToggle.addEventListener('click', () => offCanvas.classList.toggle('-translate-x-full'))

  return () => navToggle.removeEventListener('click', () => offCanvas.classList.toggle('-translate-x-full'))
})

up.compiler('.js-gallery', (element) => {
  lightGallery(element, {
    plugins: [lgThumbnail],
    // licenseKey: 'your_license_key'
    speed: 500,
    prevHtml: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path d="M40 40H0V0h40Z" data-name="Pfad 883"/>
      <path fill="#fff" d="m14.379 19.806 8.561-8.561 2.121 2.121-6.439 6.439 6.439 6.439-2.121 2.121Z" data-name="Pfad 884"/>
    </svg>`,
    nextHtml: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path d="M0 0h40v40H0z"/>
      <path fill="#fff" d="m25.621 20.194-8.561 8.561-2.121-2.121 6.439-6.439-6.439-6.439 2.121-2.121Z"/>
    </svg>`,
    hideControlOnEnd: true,
    download: false,
    getCaptionFromTitleOrAlt: false,
    thumbnail: true,
    thumbHeight: '100px',
    thumbMargin: 15,
});
})

up.compiler('.splide', (element) => {
  new Splide(element, {
    pagination: false,
  }).mount()
})

up.compiler('.js-video', (element) => {
  const player = new Plyr(element, {
    controls: ['play-large', 'mute', 'fullscreen'],
  })
})

up.compiler('.js-phone-field', (element) => {
  new Cleave(element, {
      phone: true,
      phoneRegionCode: 'ch'
  })
})

up.compiler('.js-contact-form', () => {
  hyperform.addTranslation("de",{
    TextTooLong:"Bitte kürzen Sie diesen Text auf maximal %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    TextTooShort:"Bitte verwenden Sie zumindest %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    ValueMissing:"Bitte füllen Sie dieses Feld aus.",
    CheckboxMissing:"Bitte klicken Sie dieses Kästchen an, wenn Sie fortsetzen wollen.",
    RadioMissing:"Bitte wählen Sie eine dieser Optionen.",
    FileMissing:"Bitte wählen Sie eine Datei.",
    SelectMissing:"Bitte wählen Sie einen Eintrag in der Liste.",
    InvalidEmail:"Bitte geben Sie eine E-Mail-Adresse ein.",
    InvalidURL:"Bitte geben Sie eine Internetadresse ein.",
    PatternMismatch:"Bitte halten Sie sich an das vorgegebene Format.",
    PatternMismatchWithTitle:"Bitte halten Sie sich an das vorgegebene Format: %l.",
    NumberRangeOverflow:"Bitte wählen Sie einen Wert, der nicht größer ist als %l.",
    DateRangeOverflow:"Bitte wählen Sie einen Wert, der nicht später ist als %l.",
    TimeRangeOverflow:"Bitte wählen Sie einen Wert, der nicht später ist als %l.",
    NumberRangeUnderflow:"Bitte wählen Sie einen Wert, der nicht kleiner ist als %l.",
    DateRangeUnderflow:"Bitte wählen Sie einen Wert, der nicht früher ist als %l.",
    TimeRangeUnderflow:"Bitte wählen Sie einen Wert, der nicht früher ist als %l.",
    StepMismatch:"Bitte wählen Sie einen gültigen Wert. Die zwei nähesten gültigen Werte sind %l und %l.",
    StepMismatchOneValue:"Bitte wählen Sie einen gültigen Wert. Der näheste gültige Wert ist %l.",
    BadInputNumber:"Bitte geben Sie eine Nummer ein.",
    "Please match the requested type.": "Bitte passen Sie die Eingabe dem geforderten Typ an.",
    "Please comply with all requirements.": "Bitte erfüllen Sie alle Anforderungen.",
    "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Bitte verlängern Sie diesen Text auf mindestens %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    "Please use the appropriate format.": "Bitte verwenden Sie das passende Format.",
    "Please enter a comma separated list of email addresses.": "Bitte geben Sie eine komma-getrennte Liste von E-Mail-Adressen an.",
    "Please select a file of the correct type.": "Bitte wählen Sie eine Datei vom korrekten Typ.",
    "Please select one or more files.": "Bitte wählen Sie eine oder mehrere Dateien.",
    "any value":"jeder Wert",
    "any valid value":"jeder gültige Wert",
  });
  hyperform.addTranslation("fr",{
    TextTooLong:"Veuillez raccourcir ce champ à %l caractères ou moins (vous utilisez actuellement %l caractères).",
    TextTooShort:"Veuillez utiliser au moins %l caractères pour ce champ (vous utilisez actuellement %l caractères).",
    ValueMissing:"Veuillez compléter ce champ.",
    CheckboxMissing:"Veuillez cocher cette case si vous désirez poursuivre.",
    RadioMissing:"Veuillez sélectionner l’une de ces options.",
    FileMissing:"Veuillez sélectionner un fichier.",
    SelectMissing:"Veuillez sélectionner un élément de la liste.",
    InvalidEmail:"Veuillez saisir une adresse électronique valide.",
    InvalidURL:"Veuillez saisir une URL.",
    PatternMismatch:"Veuillez modifier la valeur pour correspondre au format demandé.",
    PatternMismatchWithTitle:"Veuillez modifier la valeur du champ pour correspondre au format demandé : %l.",
    NumberRangeOverflow:"Veuillez sélectionner une valeur inférieure ou égale à %l.",
    DateRangeOverflow:"Veuillez sélectionner une valeur antérieure ou égale à %l.",
    TimeRangeOverflow:"Veuillez sélectionner une valeur antérieure ou égale à %l.",
    NumberRangeUnderflow:"Veuillez sélectionner une valeur supérieure ou égale à %l.",
    DateRangeUnderflow:"Veuillez sélectionner une valeur postérieure ou égale à %l.",
    TimeRangeUnderflow:"Veuillez sélectionner une valeur postérieure ou égale à %l.",
    StepMismatch:"Veuillez sélectionner une valeur valide. Les deux valeurs valides les plus proches sont %l et %l.",
    StepMismatchOneValue:"Veuillez sélectionner une valeur valide. La valeur valide la plus proche est %l.",
    BadInputNumber:"Veuillez saisir un nombre.",
    "Please match the requested type.": "Veuillez respecter le type de champ demandé.",
    "Please comply with all requirements.": "Veuillez respecter toutes les conditions.",
    "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Veuillez allonger ce texte pour atteindre %l caractères ou plus (vous avez écrit pour l'instant %l caractères).",
    "Please use the appropriate format.": "Veuillez utiliser le format approprié.",
    "Please enter a comma separated list of email addresses.": "Veuillez saisir une liste d'adresses électroniques séparées par des virgules.",
    "Please select a file of the correct type.": "Veuillez sélectionner un fichier du type approprié.",
    "Please select one or more files.": "Veuillez sélectionner un ou plusieurs fichiers.",
    "any value":"n'importe quelle valeur",
    "any valid value":"n'importe quelle valeur valide",
  });
  hyperform.addTranslation("it",{
    TextTooLong:"Ridurre la lunghezza del testo a %l caratteri o meno (attualmente si stanno utilizzando %l caratteri)",
    TextTooShort:"Utilizzare almeno %l caratteri (attualmente si stanno utilizzando %l caratteri)",
    ValueMissing:"Compilare questo campo",
    CheckboxMissing:"Selezionare questa casella per procedere",
    RadioMissing:"Selezionare una delle opzioni disponibili",
    FileMissing:"Selezionare un file",
    SelectMissing:"Selezionare un elemento dall’elenco",
    InvalidEmail:"Inserire un indirizzo email",
    InvalidURL:"Inserire un URL",
    PatternMismatch:"Inserire un valore nel formato richiesto",
    PatternMismatchWithTitle:"Inserire un valore nel formato richiesto: %l",
    NumberRangeOverflow:"Selezionare un valore inferiore o uguale a %l.",
    DateRangeOverflow:"Selezionare un valore precedente o uguale a %l.",
    TimeRangeOverflow:"Selezionare un valore precedente o uguale a %l.",
    NumberRangeUnderflow:"Selezionare un valore superiore o uguale a %l.",
    DateRangeUnderflow:"Selezionare un valore successivo o uguale a %l.",
    TimeRangeUnderflow:"Selezionare un valore successivo o uguale a %l.",
    StepMismatch:"Selezionare un valore valido. I due valori validi più vicini sono %l e %l",
    StepMismatchOneValue:"Selezionare un valore valido. Il valore valido più vicino è %l",
    BadInputNumber:"Inserire un numero",
  });

  hyperform.setLanguage(document.documentElement.getAttribute('lang').split('-')[0]);
  hyperform(window, {
    revalidate: 'onblur',
  });
})
